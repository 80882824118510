import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Select, message, Form, Button, Input, InputNumber } from 'antd'

import { actionCreators } from '../../store'
import styles from './index.module.css'


const ModelManagement = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [creating, setCreating] = useState(false)

    let knowledge_base_options = useSelector(state => state.get('admin').get('knowledge_base_options')).toJS()

    useEffect(() => {
        dispatch(actionCreators.getKnowledgeBaseConfig())
        .then(data => {
            form.setFieldsValue({
                llm_name: data.data.current_llm_name,
                function_call_llm_name: data.data.current_function_call_llm_name,
                min_match_score: data.data.min_match_score,
                doc_min_match_score: data.data.doc_min_match_score,
                max_dialog_count: data.data.max_dialog_count,
            })
        })
        .catch(error => {})
        .finally(() => {})
    }, [])


    const onFinish = (values) => {
        console.log('Success:', values);
        let llm_name = values.llm_name.trim()
        let function_call_llm_name = values.function_call_llm_name.trim()
        let min_match_score = values.min_match_score
        let doc_min_match_score = values.doc_min_match_score
        let max_dialog_count = values.max_dialog_count

        setCreating(true)
        dispatch(actionCreators.updateKnowledgeBaseConfig(llm_name, function_call_llm_name, min_match_score, doc_min_match_score, max_dialog_count))
            .then(data => {})
            .catch(error => {})
            .finally(() => {setCreating(false)})
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}>
                    自建知识库配置项
                </div>
            </div>

            <div className={styles.container}>
                <Form
                    form={form}
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        width: '100%',
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    preserve={false}
                >
                    <Form.Item
                        label="自建知识库选择大模型"
                        name="llm_name"
                        rules={[{ required: true, message: '自建知识库选择大模型' }]}
                    >
                        <Select
                            style={{
                                width: '50%',
                            }}
                            onChange={(value) => { console.log("选择的数据为 ", value); }}
                            options={knowledge_base_options}
                        />
                    </Form.Item>

                    <Form.Item
                        label="函数调用大模型"
                        name="function_call_llm_name"
                        rules={[{ required: true, message: '函数调用大模型' }]}
                    >
                        <Select
                            style={{
                                width: '50%',
                            }}
                            onChange={(value) => { console.log("选择的数据为 ", value); }}
                            options={knowledge_base_options}
                        />
                    </Form.Item>

                    <Form.Item
                        label="问答出图最小匹配分数"
                        name="min_match_score"
                        rules={[{ required: true, message: '问答出图最小匹配分数：0 ~ 1 之间' }]}
                    >
                        <InputNumber
                            min={0}
                            max={1}
                            step={0.01}
                        />
                    </Form.Item>

                    <Form.Item
                        label="文档最小匹配分数"
                        name="doc_min_match_score"
                        rules={[{ required: true, message: '文档最小匹配分数：0 ~ 1 之间' }]}
                    >
                        <InputNumber
                            min={0}
                            max={1}
                            step={0.01}
                        />
                    </Form.Item>

                    <Form.Item
                        label="每日对话次数上限"
                        name="max_dialog_count"
                        rules={[{ required: true, message: '每日对话次数上限' }]}
                    >
                        <InputNumber
                            min={0}
                        />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        {
                            creating ? (
                                <Button type="primary" loading>
                                    创建中
                                </Button>
                            ) : (
                                <Button type="primary" htmlType="submit">
                                    创建
                                </Button>
                            )
                        }
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default ModelManagement;