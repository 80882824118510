let global_cite_icon = require('../statics/cite_icon.png')

let isValidData = (data) => {
    return (
      typeof data === 'object'
      && data !== null
    //   && typeof data.text === 'string'
    )
}

let countOccurrences = (str, substr='data: {"text": ') => {
    // 转义特殊字符
    const escapedSubstr = substr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // 构建正则表达式对象，'g' 标志表示全局搜索
    const regex = new RegExp(escapedSubstr, 'g');

    // 使用 match 方法获取所有匹配项
    const matches = str.match(regex);

    // 如果没有匹配项，则返回 0，否则返回匹配项的数量
    return matches ? matches.length : 0;
}

let handleResText = function(text, mall_components_list, textSplitter, pingSplitter) {
    // {"text": "", "card_type": "markdown"}
    // {"text": [], "card_type": "suggestion"}

    let res = {
        markdown_arr: [],
        reasoning_arr: [],
        reasoning_time_arr: [],
        suggestion_arr: [],
        image_ids_arr: [],
        search_result_arr: [],
        recommended_tools_arr: [],
        error_arr: [],
    }

    let res_text_arr = []
    if (countOccurrences(text, textSplitter) > 0) {
        /*
            data: {"text": "可以", "card_type": "markdown"}
            data: {"text": "！", "card_type": "markdown"}
        */
        let temp_arr = text.split(textSplitter)
        for (let i = 0; i < temp_arr.length; i++) {
            let item = temp_arr[i];
            let r = '{"text": ' + item
            res_text_arr.push(r)
        }
    }

    for (let i = 0; i < res_text_arr.length; i++) {
        let temp_res = res_text_arr[i]
        try {
            if (temp_res.indexOf(pingSplitter) !== -1) {
                // 从 : ping - 开始切割到字符串末尾
                let temp_res_arr = temp_res.split(pingSplitter)
                temp_res = temp_res_arr[0]
            }

            let parsedData = JSON.parse(temp_res)
            if (isValidData(parsedData)) {
                if (parsedData.card_type === 'reasoning') {
                    res.reasoning_arr.push(parsedData.text)
                } else if (parsedData.card_type === 'reasoning_time') {
                    res.reasoning_time_arr = res.reasoning_time_arr.concat(parsedData.text)
                } else if (parsedData.card_type === 'reasoning_cite') {
                    let cite_div_text = handleConvertCiteTextToDiv(parsedData.text, mall_components_list)
                    res.reasoning_arr.push(cite_div_text)
                } else if (parsedData.card_type === 'suggestion') {
                    res.suggestion_arr = res.suggestion_arr.concat(parsedData.text)
                } else if (parsedData.card_type === 'cite') {
                    let cite_div_text = handleConvertCiteTextToDiv(parsedData.text, mall_components_list)
                    res.markdown_arr.push(cite_div_text)
                } else if (parsedData.card_type === 'index_img') {
                    res.image_ids_arr = res.image_ids_arr.concat(parsedData.text)
                } else if (parsedData.card_type === 'images') {
                    res.image_ids_arr = res.image_ids_arr.concat(parsedData.text)
                } else if (parsedData.card_type === 'markdown') {
                    res.markdown_arr.push(parsedData.text)
                } else if (parsedData.card_type === 'search_result') {
                    res.search_result_arr = res.search_result_arr.concat(parsedData.text)
                }
            } else {
                throw new Error("解析错误")
            }
        } catch (error) {
            // console.log("handleText error.message = ", error.message);
            // console.log("handleText temp_res = (", temp_res, ")")
            res.error_arr.push(temp_res)
        }
    }

    return res
}

let handleResPing = function(text, mall_components_list, textSplitter, pingSplitter) {
    let res = {
        markdown_arr: [],
        reasoning_arr: [],
        reasoning_time_arr: [],
        suggestion_arr: [],
        image_ids_arr: [],
        search_result_arr: [],
        recommended_tools_arr: [],
        error_arr: [],
    }

    try {
        if (text.indexOf(textSplitter) !== -1) {
            // 从 data: {"text": " 开始切割到字符串末尾
            let startIndex = text.indexOf(textSplitter)
            let extractedString = text.slice(startIndex);
            let obj = handleResText(extractedString, mall_components_list, textSplitter, pingSplitter)
            res.markdown_arr = res.markdown_arr.concat(obj.markdown_arr)
            res.reasoning_arr = res.reasoning_arr.concat(obj.reasoning_arr)
            res.reasoning_time_arr = res.reasoning_time_arr.concat(obj.reasoning_time_arr)
            res.suggestion_arr = res.suggestion_arr.concat(obj.suggestion_arr)
            res.image_ids_arr = res.image_ids_arr.concat(obj.image_ids_arr)
            res.search_result_arr = res.search_result_arr.concat(obj.search_result_arr)
            res.recommended_tools_arr = res.recommended_tools_arr.concat(obj.recommended_tools_arr)
            res.error_arr = res.error_arr.concat(obj.error_arr)
        } else {
            throw new Error("解析错误")
        }
    } catch (error) {
        // console.log("handleResPing error.message = ", error.message);
        // console.log("handleResPing error text = (", text, ")")
        res.error_arr.push(text)
    }

    return res
}

function handleReponseStreamChatData({text='', mall_components_list=null}) {
    text = text.trim()

    let textSplitter = 'data: {"text": '
    let pingSplitter = ': ping - '

    let res = {
        result_text: '',
        reasoning_text: '',
        reasoning_time: '',
        suggestion_arr: [],
        image_ids_arr: [],
        search_result_arr: [],
        recommended_tools_arr: [],
        error_arr: [],
    }


    let obj = {
        // markdown_arr: [],
        // reasoning_arr: [],
        // reasoning_time_arr: [],
        // suggestion_arr: [],
        // image_ids_arr: [],
        // search_result_arr: [],
        // recommended_tools_arr: [],
        // error_arr: [],
    }

    if (text.startsWith(pingSplitter)) {
        // : ping 开头的情况
        obj = handleResPing(text, mall_components_list, textSplitter, pingSplitter)
    } else {
        // if (text.startsWith(textSplitter)) {
        //     obj = handleResText(text, mall_components_list, textSplitter, pingSplitter)
        // }
        obj = handleResText(text, mall_components_list, textSplitter, pingSplitter)
    }

    res.result_text = obj.markdown_arr.join('')
    res.reasoning_text = obj.reasoning_arr.join('')
    res.reasoning_time = obj.reasoning_time_arr.join('')
    res.suggestion_arr = obj.suggestion_arr
    res.image_ids_arr = obj.image_ids_arr
    res.search_result_arr = obj.search_result_arr
    res.recommended_tools_arr = obj.recommended_tools_arr
    res.error_arr = obj.error_arr

    return res
}

// 简单的找出重叠的函数
function findOverlap(lastChunk, newText) {
    for (let i = 0; i < lastChunk.length; i++) {
        if (newText.startsWith(lastChunk.substring(i))) {
            return lastChunk.length - i;
        }
    }
    return 0;
}
// // 确定重叠部分并剔除
// newChunk 包含了 oldChunk 的内容，并且 newChunk 可能额外加了一点新的东西
// const overlap = findOverlap(oldChunk, newChunk);
// if (overlap > 0) {
//     text = newChunk.slice(overlap);
// }

var extractFirstLink = function(text, startIndex) {
    // 如果没有指定 startIndex 或指定的 startIndex 不合法，则直接 return null
    if (typeof startIndex !== 'number' || startIndex < 0) {
        return null
    }

    // 使用非贪婪匹配符 '?' 来确保只匹配到第一个 ')'
    // 正则表达式同时支持 http 和 https 链接，并且考虑了括号内的非贪婪匹配
    const regex = /\[\[(\d+)\]\]\((http[s]?:\/\/[^)]+?)\)/;
    const substringToSearch = text.substring(startIndex); // 从 startIndex 开始的子字符串
    const match = regex.exec(substringToSearch);

    if (match) {
        // 返回整个匹配的 []() 字符串以及匹配项的起始索引位置
        return {
            link: match[0],
            index: startIndex + match.index // 计算在原始文本中的实际位置
        };
    }

    return null;
}

var extractFirstImageContainer = function(text, startIndex) {
    // 如果没有指定 startIndex 或指定的 startIndex 不合法，则直接 return null
    if (typeof startIndex !== 'number' || startIndex < 0) {
        return null;
    }

    let substringToSearch = text.substring(startIndex); // 从 startIndex 开始的子字符串

    // 正则表达式来匹配 <div class="msg_images_id_container">...</div> 结构
    let regex_img = /<div class="msg_images_id_box">.*?<\/div><\/div><\/div>/s;
    let match_img = regex_img.exec(substringToSearch);
    if (match_img) {
        // 返回整个匹配的 div 容器字符串以及匹配项的起始索引位置
        return {
            link: match_img[0],
            index: startIndex + match_img.index // 计算在原始文本中的实际位置
        };
    }

    let regex_cite = /<span class="cite_container".*?<\/span><\/span><\/span><\/span>/s;
    let match_cite = regex_cite.exec(substringToSearch);
    if (match_cite) {
        return {
            link: match_cite[0],
            index: startIndex + match_cite.index // 计算在原始文本中的实际位置
        };
    }

    return null;
}

var handleStreamDataTruncation = (stream_err, chunk) => {
    const template = "data: {";
    const prefix = "{";

    function option(err, b) {
        b = b.split(template);

        let l_data = "";
        let is_err = false, j = 0;
        for (let i = 0; i < b.length; i++) {
            const x = b[i];
            if (i === 0 && x === "") {
                j = 1;
                continue;
            }

            try {
                if (i === j && err !== "") {
                    JSON.parse(err + x);
                    l_data += "data: " + err + x;
                    continue;
                }

                if (x === "") {
                    throw new Error();
                }

                JSON.parse(prefix + x);
                l_data += "data: " + prefix + x;
            } catch (e) {
                is_err = true;
                if (err !== "") {
                    err += x;
                } else {
                    err += prefix + x;
                }
            }
        }

        if (!is_err) {
            err = "";
        }

        return [err, l_data];
    }

    return option(stream_err, chunk)
}

var handleConvertHistoryRecordCiteText = ({message_text='', mall_components_list=null}) => {
    const regex = /✿(.*?)⚙/g; // 用于匹配所有✿和⚙之间的内容
    let replace_str_name = `<span class="cite_container"><span class="cite_icon_box"><img class="cite_icon" src="replace_icon" alt="引" /><span class="cite_text">replace_name</span></span><span class="cite_tooltip"><span class="cite_tooltip_content"><img class="cite_icon" src="replace_icon" alt="引" /><span class="cite_text">replace_content</span></span></span></span>`
    let replace_str_no_name = `<span class="cite_container"><span class="cite_icon_box"><img class="cite_icon" src="replace_icon" alt="引" /></span><span class="cite_tooltip"><span class="cite_tooltip_content"><img class="cite_icon" src="replace_icon" alt="引" /><span class="cite_text">replace_content</span></span></span></span>`
    let match;

    // 用来存储替换后的字符串
    let result = message_text;

    // 使用正则表达式查找所有匹配项
    while ((match = regex.exec(message_text)) !== null) {
        let has_name = false
        const temp_text = match[1]; // 提取✿和⚙之间的内容
        const split_text = temp_text.split('|'); // 按 '|' 拆分内容

        // 判断特殊的情况
        if (split_text.length === 2 && mall_components_list !== null) {
            let name = split_text[0]
            let obj = mall_components_list.find(item => item.name === name)
            if (obj) {
                let temp_cite_icon = obj.avatar
                // 找到组件的情况，并且获取组件的 avatar

                // 替换 replace_str_name 中的占位符
                let new_replace_str_name = replace_str_name.replace('replace_name', split_text[0]);
                new_replace_str_name = new_replace_str_name.replace(/replace_icon/g, temp_cite_icon);
                new_replace_str_name = new_replace_str_name.replace('replace_content', `${split_text[0]} | ${split_text[1]}`);

                // 将生成的新字符串替换到原始字符串中
                result = result.replace(`✿${temp_text}⚙`, new_replace_str_name);
                has_name = true
            }
        }

        if (!has_name) {
            // 替换 replace_str_no_name 中的占位符
            let new_replace_str_no_name = replace_str_no_name.replace('replace_name', split_text[0]);
            new_replace_str_no_name = new_replace_str_no_name.replace(/replace_icon/g, global_cite_icon);
            new_replace_str_no_name = new_replace_str_no_name.replace('replace_content', temp_text);

            // 将生成的新字符串替换到原始字符串中
            result = result.replace(`✿${temp_text}⚙`, new_replace_str_no_name);
        }
    }

    return result;
}

var handleConvertCiteTextToDiv = (text, mall_components_list) => {
    let result = ''
    if (text) {
        let split_text = text.split('|')
        if (split_text.length === 2 && mall_components_list !== null) {
            let name = split_text[0]
            let obj = mall_components_list.find(item => item.name === name)
            if (obj) {
                let temp_cite_icon = obj.avatar
                // 找到组件的情况，并且获取组件的 avatar
                result = `<span class="cite_container"><span class="cite_icon_box"><img class="cite_icon" src="${temp_cite_icon}" alt="引" /><span class="cite_text">${name}</span></span><span class="cite_tooltip"><span class="cite_tooltip_content"><img class="cite_icon" src="${temp_cite_icon}" alt="引" /><span class="cite_text">${split_text[0]} | ${split_text[1]}</span></span></span></span>`
            }
        }

        if (result === '') {
            // 没有找到 组件 的情况
            result = `<span class="cite_container"><span class="cite_icon_box"><img class="cite_icon" src="${global_cite_icon}" alt="引" /></span><span class="cite_tooltip"><span class="cite_tooltip_content"><img class="cite_icon" src="${global_cite_icon}" alt="引" /><span class="cite_text">${text}</span></span></span></span>`
        }
    }

    return result
}

export {
    handleReponseStreamChatData,
    findOverlap,
    extractFirstLink,
    extractFirstImageContainer,
    handleStreamDataTruncation,
    handleConvertHistoryRecordCiteText,
}