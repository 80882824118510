import * as actionTypes from './actionTypes'
import {
    convertUTCToLocalTime, convertGender, convertCity, convertOccupation,
    convertAge, convertModelName, convertModelMenu, findItemByProperty, fetchWithCookieRefresh,
    convertKnowledgeBaseConfigToOptions, transformknowledgeBase, chatTypeOptionsSortByWeight,
    convertCategorysToLocalFormat, getStartOfMonth, getStartOfToday, transformCategorizeData,
    handleResponseError,
} from '../../../utils'
import { message } from 'antd'


export const setActiveNavbarAction = (value) => {
    return {
        type: actionTypes.SET_ACTIVE_NAVBAR_ACTION,
        value: value,
    }
}

export const fetchUserStatisticsInfo = () => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/admin/get_users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // "start_time": "2024-06-21T07:42:19.119Z",
                // "end_time": "2024-06-21T07:42:19.119Z"
            }),
        })
        .then(data => {
            console.log("/api/admin/get_users data = ", data);

            // 当你从后端或其他地方获取到数据时，给每个数据项添加一个key属性
            const temp_data = data.data.map(item => ({
                ...item,
                registered_on: convertUTCToLocalTime(item.registered_on),
                last_login: convertUTCToLocalTime(item.last_login),
                gender: convertGender(item.gender),
                city: convertCity(item.city),
                occupation: convertOccupation(item.occupation),
                age: convertAge(item.age),
                key: item.id, // 确保key是唯一的，这里使用了数据项的id字段
            }));

            dispatch(setUserStatisticsDataAction(temp_data))
        })
        .catch(error => {})
    }
}

export const getUserStatisticsVisitInfo = (start_time, end_time='') => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let d = {
        'start_time': start_time,
        'timezone': timezone,
    }
    if (end_time) {
        d['end_time'] = end_time
    }

    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/get_active_info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(d),
        })
        .then(data => {
            console.log("/api/admin/get_active_info data = ", data);
            return data.data
        })
        .catch(error => {})
    }
}

export const setUserStatisticsDataAction = (data) => ({
    type: actionTypes.SET_USER_STATISTICS_DATA_ACTION,
    data: data,
})

export const setUserStatisticsTodayLoginNum = (value) => ({
    type: actionTypes.SET_USER_STATISTICS_TODAY_LOGIN_NUM,
    value: value
})

export const setUserStatisticsWeekLoginNum = (value) => ({
    type: actionTypes.SET_USER_STATISTICS_WEEK_LOGIN_NUM,
    value: value
})

export const fetchConversationCountInfo = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/get_query_count', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                data
                // {
                // "start_time": "2024-06-21T07:42:19.119Z",
                // "end_time": "2024-06-21T07:42:19.119Z"
                // }
            ),
        })
        .then(data => {
            console.log("/api/admin/get_query_count data = ", data);
            const temp_data = data.data
            console.log("temp_data = ", temp_data);
            dispatch(setConversationCountDataAction(temp_data))
            return true
        })
        .catch(error => {})
    }
}

export const setConversationCountDataAction = (data) => ({
    type: actionTypes.SET_CONVERSATION_COUNT_DATA_ACTION,
    data: data,
})

export const setActionUuidAction = (value) => ({
    type: actionTypes.SET_ACTIVE_UUID_ACTION,
    value: value,
})

export const fetchPersonalConversationInfo = (data) => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/admin/get_user_messages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                data
                // {
                // "uuid": uuid,
                // "start_time": "2024-06-21T07:42:19.119Z",
                // "end_time": "2024-06-21T07:42:19.119Z"
                // }
            ),
        })
        .then(data => {
            console.log("/api/admin/get_user_messages data = ", data);
            let temp_data = data.data.query.concat(data.data.re_query)
            let admin_chat_type_options = getState().get("admin").get("admin_chat_type_options").toJS()

            temp_data = temp_data.map(item => ({
                ...item,
                query_at: convertUTCToLocalTime(item.query_at),
                chat_type: convertModelName(admin_chat_type_options, item.chat_type),
            }))

            dispatch(setPersonalConversationDataAction(temp_data))
        })
        .catch(error => {})
    }
}

export const setPersonalConversationDataAction = (data) => ({
    type: actionTypes.SET_PERSONAL_CONVERSATION_DATA_ACTION,
    data: data,
})

export const fetchKnowledgeBaseList = () => {
    return (dispatch, getState) => {
        // return fetchWithCookieRefresh('/api/knowledge_base_list.json', {
        fetchWithCookieRefresh('/api/knowledge_base/list', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/knowledge_base/list data = ", data);
            let temp_data = data.data
            let menu = convertModelMenu(temp_data)

            if (menu.length > 0) {
                let current_active_model = getState().get("admin").get("active_model")
                let obj = temp_data.find(item => item.collection === current_active_model)
                if (obj) {
                    console.log("存在之前的 active_model");
                } else {
                    let model_key = temp_data[0].collection
                    let model_name = temp_data[0].name
                    dispatch(setActiveModelAction(model_key, model_name))
                }
            }
            dispatch(setKnowledgeBaseMenuAction(menu))
            dispatch(setKnowledgeBaseListAction(temp_data))
        })
        .catch(error => {})
    }
}

export const setKnowledgeBaseMenuAction = (data) => ({
    type: actionTypes.SET_KNOWLEDGE_BASE_MENU_ACTION,
    data: data,
})

export const setKnowledgeBaseListAction = (data) => ({
    type: actionTypes.SET_KNOWLEDGE_BASE_LIST_ACTION,
    data: data,
})

export const setActiveModelAction = (key, name) => ({
    type: actionTypes.SET_ACTIVE_MODEL_ACTION,
    model_key: key,
    model_name: name,
})

export const createKnowledgeBase = (formData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/knowledge_base/create', {
            method: 'POST',
            body: formData,
        })
        .then(data => {
            console.log("/api/knowledge_base/create data = ", data);
            // 重新获取一遍知识库列表
            dispatch(fetchKnowledgeBaseList())
            message.success(data.msg)
            dispatch(setKnowledgeBaseShowContentAction(''))
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const deleteKnowledgeBase = (name) => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/knowledge_base/delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name": name,
            }),
        })
        .then(data => {
            console.log("/api/knowledge_base/delete data = ", data);
            // 重新获取一遍知识库列表
            dispatch(fetchKnowledgeBaseList())
            dispatch(setKnowledgeBaseShowContentAction(''))
            message.success(data.msg)
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const fetchSliceDetails = (knowledge_base_name, file_name) => {
    let args = `?knowledge_base_name=${knowledge_base_name}&file_name=${file_name}`
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/knowledge_base/file/document/list' + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/knowledge_base/file/document/list data = ", data);
            dispatch(setFileSliceListAction(data.data))
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const setKnowledgeBaseShowContentAction = (value) => ({
    type: actionTypes.SET_KNOWLEDGE_BASE_SHOW_CONTENT,
    value: value,
})

export const setFileSliceListAction = (data) => ({
    type: actionTypes.SET_FILE_SLIT_LIST_ACTION,
    data: data,
})

export const fetchUploadFiles = (formData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/knowledge_base/files/upload', {
            method: 'POST',
            body: formData,
        }, { timeout: 1000*60000, })
        .then(data => {
            console.log("/api/knowledge_base/files/upload data = ", data);
            let fail_data = Object.values(data.data.fail)
            console.log("fail_data = ", fail_data);
            fail_data.forEach((item) => {
                message.error(item)
            })

            let success_data = Object.values(data.data.success)
            console.log("success_data = ", success_data);
            success_data.forEach((item) => {
                message.success(item)
            })
            dispatch(fetchKnowledgeBaseList())
            dispatch(setKnowledgeBaseShowContentAction(''))

            return data
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const deleteKnowledgeBaseFile = (knowledge_base_name, file_name) => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/knowledge_base/file/delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "kb_name": knowledge_base_name,
                "file_name": file_name,
            }),
        })
        .then(data => {
            console.log("/api/knowledge_base/file/delete data = ", data);
            // 重新获取一遍知识库列表
            dispatch(fetchKnowledgeBaseList())
            message.success(data.msg)
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const fetchSingleKnowledgeBaseList = (active_model) => {
    return (dispatch, getState) => {
        let knowledge_base_menu = getState().get("admin").get("knowledge_base_menu").toJS()
        let obj = findItemByProperty(knowledge_base_menu, 'key', active_model)
        console.log("obj = ", obj);
        return fetchWithCookieRefresh('/api/knowledge_base/files/upload/status?knowledge_base_name=' + obj.label, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/knowledge_base/files/upload/status data = ", data);
            let temp_data = data.data
            let knowledge_base_list = getState().get("admin").get("knowledge_base_list").toJS()
            let l = knowledge_base_list.map(item => item.collection === active_model ? temp_data : item);
            dispatch(setKnowledgeBaseListAction(l))
        })
        .catch(error => {})
    }
}

export const fetchUploadImageFiles = (formData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/knowledge_base/pictures/upload', {
            method: 'POST',
            body: formData,
        }, { timeout: 1000*60000, })
        .then(data => {
            console.log("/api/knowledge_base/pictures/upload data = ", data);
            message.success("图片上传成功！")
            dispatch(fetchKnowledgeBaseList())
            dispatch(setKnowledgeBaseShowContentAction(''))

            return data
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const fetchVersionUpdateList = () => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/admin/list_release_record', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/list_release_record data = ", data);
            let temp_data = data.data.release_record
            dispatch(setVersionUpdateListAction(temp_data))
        })
        .catch(error => {})
    }
}

export const setVersionUpdateListAction = (data) => ({
    type: actionTypes.SET_VERSION_UPDATE_LIST_ACTION,
    data: data,
})

export const isShowVersionUpdateImage = (status) => ({
    type: actionTypes.IS_SHOW_VERSION_UPDATE_IMAGE,
    status: status,
})

export const showVersionUpdateImageId = (value) => ({
    type: actionTypes.SHOW_VERSION_UPDATE_IMAGE_ID,
    value: value,
})

export const createVersionUpdate = (formData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/pre_publish_release', {
            method: 'POST',
            body: formData,
        })
        .then(data => {
            console.log("/api/admin/pre_publish_release data = ", data);
            message.success(data.msg)
            dispatch(fetchVersionUpdateList())
            return data
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const getUsersPermission = () => {
    return (dispatch, getState) => {
        // 获取所有的用户权限信息
        return fetchWithCookieRefresh('/api/rbac/get_users_permissions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/rbac/get_users_permissions data = ", data);
            dispatch(setPermissionUserListAction(data.data))
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const setPermissionUserListAction = (data) => ({
    type: actionTypes.SET_PERMISSION_USER_LIST_ACTION,
    data: data,
})

export const setPermissionUserPageAction = (value) => ({
    type: actionTypes.SET_PERMISSION_USER_PAGE_ACTION,
    value: value,
})

export const setPermissionUserUuidAction = (value) => ({
    type: actionTypes.SET_PERMISSION_USER_UUID_ACTION,
    value: value,
})

export const getGroupsPermissions = () => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/get_groups_permissions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/rbac/get_groups_permissions data = ", data);
            dispatch(setPermissionGroupListAction(data.data))
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const setPermissionGroupListAction = (data) => ({
    type: actionTypes.SET_PERMISSION_GROUP_LIST_ACTION,
    data: data,
})

export const setPermissionGroupPageAction = (value) => ({
    type: actionTypes.SET_PERMISSION_GROUP_PAGE_ACTION,
    value: value,
})

export const setPermissionGroupIdAction = (value) => ({
    type: actionTypes.SET_PERMISSION_GROUP_NAME_ACTION,
    value: value,
})

export const updateUserGroups = (uuid, groups) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/update_user_groups', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "uuid": uuid,
                "groups": groups
            }),
        })
        .then(data => {
            console.log("/api/rbac/update_user_groups data = ", data);
            message.success(data.msg)
            dispatch(getUserGroups(uuid))
        })
        .catch(error => {})
    }
}

export const updateUserPermissions = (uuid, permissions) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/update_user_permissions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "uuid": uuid,
                "permissions": permissions
            }),
        })
        .then(data => {
            console.log("/api/rbac/update_user_permissions data = ", data);
            message.success(data.msg)
            dispatch(getUserPermissions(uuid))
        })
        .catch(error => {})
    }
}


export const updateGroupUsers = (name, uuids) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/update_group_users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name": name,
                "uuids": uuids
            }),
        })
        .then(data => {
            console.log("/api/rbac/update_group_users data = ", data);
            message.success(data.msg)
            dispatch(getGroupUsers(name))
        })
        .catch(error => {})
    }
}

export const updateGroupPermissions = (name, permissions) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/update_group_permissions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name": name,
                "permissions": permissions
            }),
        })
        .then(data => {
            console.log("/api/rbac/update_group_permissions data = ", data);
            message.success(data.msg)
            dispatch(getGroupPermissions(name))
        })
        .catch(error => {})
    }
}

export const getUserGroups = (uuid) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/get_user_groups?uuid=' + uuid, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/rbac/get_user_groups data = ", data);
            let user_group = data.data?.user_group || []
            let permission_user_list = getState().get("admin").get("permission_user_list").toJS()
            for (let item of permission_user_list) {
                if (item.uuid === uuid) {
                    item.groups = user_group
                    break
                }
            }
            dispatch(setPermissionUserListAction(permission_user_list))
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const getUserPermissions = (uuid) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/get_user_permissions?uuid=' + uuid, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/rbac/get_user_permissions data = ", data);
            let user_permissions = data.data
            let permission_user_list = getState().get("admin").get("permission_user_list").toJS()
            for (let item of permission_user_list) {
                if (item.uuid === uuid) {
                    item.permissions = user_permissions
                    break
                }
            }
            dispatch(setPermissionUserListAction(permission_user_list))
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const getGroupUsers = (name) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/get_group_users?group_name=' + name, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/rbac/get_group_users data = ", data);
            let group_user = data.data.group_user
            let permission_group_list = getState().get("admin").get("permission_group_list").toJS()
            for (let item of permission_group_list) {
                if (item.name === name) {
                    item.users = group_user
                    break
                }
            }
            dispatch(setPermissionGroupListAction(permission_group_list))
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const getGroupPermissions = (name) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/get_group_permissions?group_name=' + name, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/rbac/get_group_permissions data = ", data);
            let permissions = data.data
            let permission_group_list = getState().get("admin").get("permission_group_list").toJS()
            for (let item of permission_group_list) {
                if (item.name === name) {
                    item.permissions = permissions
                    break
                }
            }
            dispatch(setPermissionGroupListAction(permission_group_list))
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}


export const createGroup = (name, description) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/create_group', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name": name,
                "description": description,
            }),
        })
        .then(data => {
            console.log("/api/rbac/create_group data = ", data);
            message.success(data.msg)
            dispatch(getGroupsPermissions())
            return data
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const setKnowledgeBaseOptionsAction = (data) => ({
    type: actionTypes.SET_KNOWLEDGE_BASE_OPTIONS_ACTION,
    data: data,
})

export const getKnowledgeBaseConfig = () => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/knowledge_base/config/get', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/knowledge_base/config/get data = ", data);
            dispatch(setKnowledgeBaseOptionsAction(convertKnowledgeBaseConfigToOptions(data.data.llm_names)))
            return data
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const updateKnowledgeBaseConfig = (llm_name, function_call_llm_name, min_match_score, doc_min_match_score, max_dialog_count) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/knowledge_base/config/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "llm_name": llm_name,
                "function_call_llm_name": function_call_llm_name,
                "min_match_score": min_match_score,
                "doc_min_match_score": doc_min_match_score,
                "max_dialog_count": max_dialog_count,
            }),
        })
        .then(data => {
            console.log("/api/knowledge_base/config/update data = ", data);
            message.success(data.msg)
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const setEditKnowledgeBaseName = (value) => ({
    type: actionTypes.SET_EDIT_KNOWLEDGE_BASE_NAME,
    value: value,
})

export const updateKnowledgeBase = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/knowledge_base/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(data => {
            console.log("/api/knowledge_base/update data = ", data);
            dispatch(fetchKnowledgeBaseList())
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const deleteReleaseRecord = (id) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/delete_release_record', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "id": id,
            }),
        })
        .then(data => {
            console.log("/api/admin/delete_release_record data = ", data);
            message.success(data.msg)
            dispatch(fetchVersionUpdateList())
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const setFilterDates = (data) => ({
    type: actionTypes.SET_FILTER_DATES,
    data: data,
})

export const fetchAvgDailyActiveInfo = () => {
    return (dispatch, getState) => {
        let start_time = getStartOfMonth()
        let end_time = getStartOfToday()
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        fetchWithCookieRefresh('/api/admin/avg_daily_active_info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "start_time": start_time,
                "end_time": end_time,
                "timezone": timezone,
            }),
        })
        .then(data => {
            console.log("/api/admin/avg_daily_active_info data = ", data);
            dispatch(setUserStatisticsMonthDayAvg(data.data.avg))
        })
        .catch(error => {})
    }
}

export const setUserStatisticsMonthDayAvg = (value) => ({
    type: actionTypes.SET_USER_STATISTICS_MONTH_DAY_AVG,
    value: value,
})

export const setKnowledgeBaseListSearchName = (value) => ({
    type: actionTypes.SET_KNOWLEDGE_BASE_LIST_SEARCH_NAME,
    value: value,
})

export const fetchUserCategorize = (uuid, isVerified, category) => {
    let args = `?user=${uuid}&category=${category}&isVerified=${isVerified === 'yes' ? true : false}`
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/message/category/get' + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/message/category/get data = ", data);
            let r = transformCategorizeData(data.data)
            return r
        })
        .catch(error => {})
    }
}

export const fetchAllUserCategorize = (isVerified, category) => {
    let args = `?category=${category}&is_verified=${isVerified === 'yes' ? true : false}`

    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/message/category/list' + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/message/category/list data = ", data);
            return data
        })
        .catch(error => {})
    }
}

export const fetchUpdateUserCategorizeMessage = (select_item, number, isDelete, start_time='', end_time='') => {
    let args = `?category=${select_item}&question_count=${number}&delete_message_category=${isDelete}`
    if (start_time) {
        args += `&start_time=${start_time}`
    }

    if (end_time) {
        args += `&end_time=${end_time}`
    }

    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/message/category/match' + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }, { timeout: 1000*60000, })
        .then(data => {
            console.log("/api/admin/message/category/match");
            return data
        })
        .catch(error => { throw error })
    }
}

export const fetchUserCategorizeDetail = (category_name, category, isVerified, active_uuid='') => {
    let args = ''
    if (active_uuid) {
        args = `?category_name=${category_name}&category=${category}&user=${active_uuid}&is_verified=${isVerified === 'yes' ? true : false}`
    } else {
        args = `?category_name=${category_name}&category=${category}&is_verified=${isVerified === 'yes' ? true : false}`
    }

    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/message/category/detail' + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/message/category/detail data = ", data);
            return data
        })
        .catch(error => { throw error })
    }
}

export const fetchCategoryList = () => {
    return (dispatch, getState) => {
        // return fetchWithCookieRefresh('/api/admin_category_list.json', {
        return fetchWithCookieRefresh('/api/admin/category/get', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/category/get data = ", data);
            return data
        })
        .catch(error => { throw error })
    }
}

export const createCategoryList = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/category/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(data => {
            console.log("/api/admin/category/create data = ", data);
            return data
        })
        .catch(error => { throw error })
    }
}

export const updateCategoryList = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/category/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(data => {
            console.log("/api/admin/category/update data = ", data);
            return data
        })
        .catch(error => { throw error })
    }
}

export const deleteCategoryList = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/category/delete', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(data => {
            console.log("/api/admin/category/delete data = ", data);
            return data
        })
        .catch(error => { throw error })
    }
}

export const fetchCategoryMatchNumber = (category, start_time='', end_time='') => {
    let args = `?category=${category}`
    if (start_time) {
        args += `&start_time=${start_time}`
    }

    if (end_time) {
        args += `&end_time=${end_time}`
    }

    return (dispatch, getState) => {
        return fetchWithCookieRefresh(`/api/admin/message/category/match_number` + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/message/category/match_number data = ", data);
            return data
        })
        .catch(error => { throw error })
    }
}

export const updateMessageCategory = (data, category) => {
    let args = `?category=${category}`

    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/message/category/update' + args, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(data => {
            console.log("/api/admin/message/category/update data = ", data);
            return data
        })
        .catch(error => {})
    }
}

export const adminInitModelInfo = () => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/knowledge_base/list', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/knowledge_base/list data = ", data);
            let temp_data = transformknowledgeBase(data.data)
            let r_data = chatTypeOptionsSortByWeight(temp_data)
            dispatch(adminChatTypeOptionsAction(r_data))
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const adminChatTypeOptionsAction = (data) => {
    return {
        type: actionTypes.ADMIN_CHAT_TYPE_OPTIONS_ACTION,
        data: data,
    }
}

export const getMessageCategoryVerify = (category) => {
    let args = `?category=${category}`

    return (dispatch, getState) => {
        return fetchWithCookieRefresh(`/api/admin/message/category/verify` + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/message/category/verify` data = ", data);
            return data
        })
        .catch(error => { throw error })
    }
}

export const getAdminKnowledgeBaseCategorys = () => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/knowledge_base/categorys', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/knowledge_base/categorys data = ", data);
            let d = convertCategorysToLocalFormat(data.data.categorys)
            dispatch(adminExpertCategoryListAction(d))
        })
        .catch(error => {})
    }
}

export const adminExpertCategoryListAction = (data) => ({
    type: actionTypes.ADMIN_EXPERT_CATEGORY_LIST_ACTION,
    data: data,
})

export const getComponentListed = (component_type) => {
    let args = `?component_type=${component_type}`

    return (dispatch, getState) => {
        // fetchWithCookieRefresh('/api/admin_component_list.json', {
        fetchWithCookieRefresh('/api/admin/component/list' + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/component/list data = ", data);
            let d = data.data.map((item, index) => {
                item['has_add'] = 0
                if (index === 0) {
                    item['has_select'] = true
                } else {
                    item['has_select'] = false
                }
                return item
            })

            if (component_type === 'document_kb') {
                dispatch(componentDocumentListedAction(d))
            } else {
                dispatch(componentPictureListedAction(d))
            }
        })
        .catch(error => {})
    }
}

export const componentPictureListedAction = (data) => ({
    type: actionTypes.COMPONENT_PICTURE_LISTED,
    data: data,
})

export const componentDocumentListedAction = (data) => ({
    type: actionTypes.COMPONENT_DOCUMENT_LISTED,
    data: data,
})

export const deleteAdminSingleComponent = (data, authcode) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/component/delete', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authcode': authcode,
            },
            body: JSON.stringify(data),
        })
        .then(data => {
            console.log("/api/admin/component/delete data = ", data);
            dispatch(getComponentListed('document_kb'))
            dispatch(getComponentListed('picture_kb'))
            return data
        })
        .catch(error => { throw error })
    }
}

export const currentEditComponentName = (value) => ({
    type: actionTypes.CURRENT_EDIT_COMPONENT_NAME,
    value: value,
})

export const currentEditComponentType = (value) => ({
    type: actionTypes.CURRENT_EDIT_COMPONENT_TYPE,
    value: value,
})

export const createOrUpdateComponent = (formData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/component/create_or_update', {
            method: 'POST',
            body: formData,
        })
        .then(data => {
            console.log("/api/admin/component/create_or_update data = ", data);
            // 重新获取一遍知识库列表
            message.success(data.msg)
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const getComponentCategory = () => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/admin/component/category', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/component/category data = ", data);
            let d = data.data.map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                }
            })
            dispatch(componentListedSelectMenu(d))
        })
        .catch(error => {})
    }
}

export const componentListedSelectMenu = (data) => ({
    type: actionTypes.COMPONENT_LISTED_SELECT_MENU,
    data: data,
})

export const getComponentContentList = (component_name, filename='') => {
    let args = `?component_name=${component_name}`
    if (filename) {
        args = args + `&filename=${filename}`
    }

    return (dispatch, getState) => {
        // return fetchWithCookieRefresh('/api/component_content_list.json', {
        return fetchWithCookieRefresh('/api/admin/component/content/list' + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/component/content/list data = ", data);
            return data
        })
        .catch(error => {})
    }
}

export const deleteComponentContent = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/component/content/delete', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(data => {
            console.log("/api/admin/component/content/delete data = ", data);
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const createComponentDocumentContent = (formData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/component/content/document/create', {
            method: 'POST',
            body: formData,
        })
        .then(data => {
            console.log("/api/admin/component/content/document/create data = ", data);
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const createPictureComponentContent = (formData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/component/content/picture/create', {
            method: 'POST',
            body: formData,
        })
        .then(data => {
            console.log("/api/admin/component/content/picture/create data = ", data);
            // 重新获取一遍知识库列表
            message.success(data.msg)
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const updatePictureComponentContent = (formData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/component/content/picture/update', {
            method: 'POST',
            body: formData,
        })
        .then(data => {
            console.log("/api/admin/component/content/picture/update data = ", data);
            // 重新获取一遍知识库列表
            message.success(data.msg)
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const movePictureComponentContent = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/component/content/picture/move', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(data => {
            console.log("/api/admin/component/content/picture/move data = ", data);
            message.success(data.msg)
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const copyPictureComponentContent = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/component/content/picture/copy', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(data => {
            console.log("/api/admin/component/content/picture/copy data = ", data);
            message.success(data.msg)
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const getAdminComponentActivate = (component_name, authcode) => {
    let args = `?component_name=${component_name}`

    return (dispatch, getState) => {
        return fetchWithCookieRefresh(`/api/admin/component/activate` + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authcode': authcode,
            },
        }, { timeout: 10000*60000, })
        .then(data => {
            console.log("/api/admin/component/activate` data = ", data);
            return data
        })
        .catch(error => { throw error })
    }
}

export const getAdminComponentDeactivate = (component_name, authcode) => {
    let args = `?component_name=${component_name}`

    return (dispatch, getState) => {
        return fetchWithCookieRefresh(`/api/admin/component/deactivate` + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authcode': authcode,
            },
        })
        .then(data => {
            console.log("/api/admin/component/deactivate` data = ", data);
            return data
        })
        .catch(error => { throw error })
    }
}

export const getAdminBannerImagesList = () => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh(`/api/admin/banner/images/list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/banner/images/list` data = ", data);
            return data
        })
        .catch(error => { throw error })
    }
}

export const uploadAdminBannerImages = (formData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/banner/images/upload', {
            method: 'POST',
            body: formData,
        })
        .then(data => {
            console.log("/api/admin/banner/images/upload data = ", data);
            // 重新获取一遍知识库列表
            message.success(data.msg)
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const updateAdminBannerImages = (jsonData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/banner/images/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: jsonData,
        })
        .then(data => {
            console.log("/api/admin/banner/images/update data = ", data);
            // 重新获取一遍知识库列表
            message.success(data.msg)
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const getAdminBannerImagesPreview = () => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh(`/api/admin/banner/images/preview`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/banner/images/preview` data = ", data);
            return data
        })
        .catch(error => { throw error })
    }
}

export const getAdminBannerImagesPublish = () => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh(`/api/admin/banner/images/publish`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/banner/images/publish");
        })
        .catch(error => { handleResponseError(message, error) })
    }
}

export const deleteAdminBannerImages = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/banner/images/delete', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(data => {
            console.log("/api/admin/banner/images/delete data = ", data);
            return data
        })
        .catch(error => { throw error })
    }
}

export const replaceOrExtendComponentContent = (data, authcode) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/component/replace_or_extend', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authcode': authcode,
            },
            body: JSON.stringify(data),
        })
        .then(data => {
            console.log("/api/admin/component/replace_or_extend data = ", data);
            message.success(data.msg)
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const adminAuthCodeSend = () => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/component/auth_code/send', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/component/auth_code/send data = ", data);
            return true
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const getComponentStatistics = () => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/component/statistics', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/component/statistics data = ", data);
            return data.data
        })
        .catch(error => {})
    }
}

export const getComponentContentStatistics = (component_name) => {
    let args = `?component_name=${component_name}`

    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/component/content/statistics' + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/admin/component/content/statistics data = ", data);
            return data.data
        })
        .catch(error => {})
    }
}
